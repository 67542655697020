import React from 'react';
import Helmet from 'react-helmet';
import PageHeader from 'components/PageHeader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Paragraph } from 'components/Typography';
import styles from './index.module.less';
import image1 from 'images/project-7a.png';
import image2 from 'images/project-7b.png';
import image3 from 'images/project-7c.png';
import image4 from 'images/project-7d.png';
import image5 from 'images/project-7e.png';
import Layout from 'components/Layout';

const Page = () => (
  <Layout>
    <Helmet
      title="What a difference a roof makes!"
      meta={[
        {
          name: 'description',
          content:
            'Aaron and his wife bought their Wellington home seventeen years ago. Whilst it represented &lsquo;home&rsquo; and security for their three-generation family, the old house was in need of maintenance.'
        }
      ]}>
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Feels like a new home"
      intro="Aaron and his wife bought their Wellington home seventeen years ago. Whilst it represented &lsquo;home&rsquo; and security for their three-generation family, the old house was in need of maintenance."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            For the past seven years, the roof has leaked. Gradually at first, then more severely
            over the last two years. Aaron's efforts to patch things up were thwarted by the
            increasing rust and his own health issues. At times, twenty buckets were used to catch
            the water! The family grew used to sleeping with the sound of dripping, even moving the
            children's bunk beds when leaks fell on them.
          </Paragraph>
          <Paragraph>
            In one storm late 2018, a sheet of water cascaded from the ceiling, resulting in
            flooding throughout two rooms. Such an unlucky event put the ceilings at risk of
            collapse.
          </Paragraph>
          <Paragraph>
            Aaron despaired. The banks wouldn&rsquo;t lend them money for essential roof repairs.
            What were they to do?
          </Paragraph>
          <Paragraph>
            Whilst visiting, Aaron&rsquo;s sister-in-law witnessed the dire need and was moved to
            seek help. She came across Ryhaven Trust and encouraged Aaron to write an application
            for assistance.
          </Paragraph>
          <Paragraph>
            Ryhaven&rsquo;s builder Bernard set to work, along with{' '}
            <a href="https://www.carters.co.nz">Carters</a>,{' '}
            <a href="https://www.metalcraftgroup.co.nz">Metalcraft Roofing</a>,{' '}
            <a href="http://www.geeves.co.nz">Geeves Scaffolding</a>, and{' '}
            <a href="https://www.dulux.co.nz">Dulux</a> &ndash; all providing valuable practical
            support.
          </Paragraph>
          <Paragraph>
            Externally, the rusting roof and guttering were dismantled and replaced. Internally,
            ceilings and some walls in damaged rooms were replaced and new insulation installed.
          </Paragraph>
          <Paragraph>
            Aaron and his family quickly mastered the art of interior painting and now the rooms
            look brand new.
          </Paragraph>
          <Paragraph>
            Aaron says he feels immense relief and is again happy to invite people into their home.
            &ldquo;Ryhaven, you have been a godsend&hellip; Thank you!!!&rdquo;
          </Paragraph>
          <img className={styles.image} src={image4} alt="" />
          <img className={styles.image} src={image5} alt="" />
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <img className={styles.image} src={image2} alt="" />
          <img className={styles.image} src={image3} alt="" />
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Page;
